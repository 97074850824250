<template>
  <div id="service">
    <div class="title">
      <h1>選擇Finger讓你投資</h1>
      <h2>您可以選擇想註冊的身分了解我們提供的服務</h2>
    </div>

    <div class="content">
      <div class="btnWindow">
        <div class="btn" @click="goPersonalService()"><img src="@/assets/img/service/solo.png"><div class="mask"></div><div class="text"><h1>個人用戶</h1><p>我想申請Finger Taring app使用</p></div></div>
        <div class="btn"><img src="@/assets/img/service/company.png"><div class="mask"></div><div class="text"><h1>機構用戶</h1><p>我想申請Finger Taring app使用</p></div></div>
        <div class="btn"><img src="@/assets/img/service/bank.png"><div class="mask"></div><div class="text"><h1>其他金融機構</h1><p>我想申請Finger Taring app使用</p></div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Service',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{
    goPersonalService(){
      this.$router.push('/service/personal')
    }
  },
}
</script>

<style scoped>
#service{
  width: 100vw;
  height: 100vh;
  background: #212429;
  transition: .6s;
  box-sizing: border-box;
}
.title{
  color: aliceblue;
  text-align: center;
  padding-top: 8%;
  box-sizing: border-box;
}
.content{
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.btnWindow{
  width: 80%;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn{
  position: relative;
  width: 440px;
  height: 320px;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
  transition: .4s;
}
.btn:hover{
  transform: translateY(10px);
}
.mask{
  position: absolute;
  background: #000;
  opacity: .5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.text{
  position: absolute;
  z-index: 9;
  color: #fff;
  bottom: 38px;
  left: 30px;
}
.title h1{
  font-size: 60px;
}
img{
  height: 100%;
}
</style>
